<template>
  <div class="flex flex-row">
    <div class="flex items-center justify-center w-full">
      <BaseCard class="w-full">
        <div class="lg:mt-20 mt-0 mx-2">
          <h2 class="mb-5 text-4xl font-light text-center text-gray-800">
            {{ $t("setNewPassword") }}
          </h2>
          <h3 class="text-gray-800 mb-10 text-center">
            {{ $t('pleaseProvideDepositedEmailAddress') }}
          </h3>
          <form class="" @submit.prevent="setNewPassword">
            <TextField
              type="email"
              v-model="email"
              :label="$t('eMailAddress')"
              :floating="hasFloatingWidth"
              required
            />
            <Button type="submit" class="w-full mt-6" primary>
              {{ $t("confirm") }}
            </Button>
          </form>
        </div>
      </BaseCard>
    </div>
    <DialogModal
          alert
          v-model="modalState.forgottenPasswordFailed"
          :primary-text="$t('information')"
          :secondary-text="$t('forgottenPasswordFailed')"
          @approve="modalState.forgottenPasswordFailed=false"
      />
      <DialogModal
          alert
          v-model="modalState.forgottenPasswordSuccess"
          :primary-text="$t('information')"
          :secondary-text="$t('forgottenPasswordSuccess')"
          @approve="toLogin"
      />
  </div>
</template>

<script>

import BaseCard from './components/BaseCard.vue';
import DialogModal from '../../components/DialogModal.vue';

export default {
  name: 'Password',
  components: { BaseCard, DialogModal },
  data() {
    return {
      email: '',
    };
  },
  computed: {
    hasFloatingWidth() {
      return window.innerWidth < 1024;
    },
    modalState: {
      get() {
        return this.$store.state.memberArea.modalState;
      },
      set(value) {
        this.$store.commit('setModalState', { value });
      },
    },
  },
  methods: {
    setNewPassword() {
      this.$store.commit('setEmail', this.email);
      this.$store.dispatch('forgottenPassword');
    },
    toLogin() {
      this.modalState.forgottenPasswordSuccess = false;
      this.$router.push({ name: 'member-login' });
    },
  },
};
</script>

<style scoped>

@media (max-width: 1024px) {
  .w-200px {
    width: 200px;
  }
}

</style>
