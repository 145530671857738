<template>
  <div class="lg:mt-16 lg:shadow flex flex-row base-card-size w-full justify-center">
    <!-- left static -->
    <div class="bg-gray-800 w-1/2 hidden lg:flex relative background-fitstar">
      <div class="member-area-title">
        <div>Member</div>
        <div class="area-line-height">Area</div>
      </div>
    </div>
    <!-- right slot -->
    <div class="lg:w-1/2 lg:pr-20 lg:pl-20 overflow-y-auto">
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.base-card-size {
  min-height: 68vh;
  width: 120vh;
}

.member-area-title {
  @apply flex flex-col text-white absolute text-5xl;
  right: 55px;
  top: 350px;
  font-weight: 300;
}

.area-line-height {
  line-height: 30px;
}
</style>
